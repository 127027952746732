// useMouseX.js
import { useContext } from 'react'
import ActiveWorksContext from '../contexts/ActiveWorksContext' // Import the new context

export const useWorks = () => {
  const context = useContext(ActiveWorksContext) // use the new context

  if (context === undefined) {
    throw new Error('WorksActive must be used within a WorksActiveContextProvider')
  }

  return context
}

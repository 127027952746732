import React, { useState } from 'react'
import ActiveWorksContext from '../contexts/ActiveWorksContext'

const ActiveWorksContextProvider = ({ children }) => {
  const [activeWorks, setActiveWorks] = useState(null)
  const [activeAbout, setActiveAbout] = useState(false)

  return (
    <ActiveWorksContext.Provider value={{ activeWorks, setActiveWorks, activeAbout, setActiveAbout }}>
      {children}
    </ActiveWorksContext.Provider>
  )
}

export default ActiveWorksContextProvider

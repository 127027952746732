import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player/vimeo'
import styles from './single-work.module.css'
import { useMouseX } from '../hooks/useMouseX'
import { useWorks } from '../hooks/useWorks'
import { useFocus } from '../hooks/useFocus'
import { Flipped } from 'react-flip-toolkit'

const SingleWork = ({ data, transformValue, index, randomXY }) => {
  const { mouseX, mouseArea } = useMouseX()
  const { activeWorks, setActiveWorks } = useWorks()
  const { focusWork, setFocusWork } = useFocus()

  const workRef = useRef(null)
  const workInfo = useRef(null)

  const [videoHeight, setVideoHeight] = useState(null)
  const [videoWidth, setVideoWidth] = useState(null)
  const [aspectRatio, setAspectRatio] = useState(null)

  const [isSingleWorkAnimationDone, setIsSingleWorkAnimationDone] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateTranslateX = (mouseX, transformValue) => {
    if (activeWorks && focusWork !== null) {
      // If activeWorks is true, return 0 to place the divs in two columns
      return 0
    } else if (mouseArea === 'left' && !activeWorks && focusWork === null) {
      if (mouseX === null) {
        return transformValue // Default state, off-screen
      } if (mouseX >= 50) {
        return transformValue + 100
      } else {
        // Map mouseX to range between transform Value and transformValue + 100
        const translateRange = 100 // Total range of movement
        const translateX = transformValue + ((mouseX / 50) * translateRange)
        return Math.min(translateX, translateRange) // Ensure it doesn't exceed transformValue + 100
      }
    } else {
      return transformValue
    };
  }

  const [translateX, setTranslateX] = useState(calculateTranslateX(mouseX, transformValue))
  // const translateX = calculateTranslateX(mouseX, transformValue)

  useEffect(() => {
    setTranslateX(calculateTranslateX(mouseX, transformValue))
  }, [calculateTranslateX, mouseX, transformValue])

  // useEffect(() => {
  //   if (videoWidth && videoHeight) {
  //     const aspectRatio = videoWidth / videoHeight
  //     setAspectRatio(aspectRatio)
  //   } if (activeWorks !== null) {
  //     const aspectRatio = videoWidth / (videoHeight + workInfo.current.offsetHeight)
  //     setAspectRatio(aspectRatio)
  //     console.log(aspectRatio)
  //   }
  // }, [activeWorks, videoWidth, videoHeight])

  // const parentStyle = aspectRatio ? { aspectRatio } : {}

  const workStyle = activeWorks && focusWork === null
    ? index % 2 === 0
      ? { marginRight: `${randomXY.x}%`, marginTop: `${randomXY.y}%` }
      : { marginLeft: `${randomXY.x}%`, marginTop: `${randomXY.y}%` }
    : focusWork !== null
      ? { marginLeft: '0' }
      : { marginLeft: `${translateX}%` }
  // useEffect(() => {
  //   if (workRef.current) {
  //     initialPositionRef.current = workRef.current.getBoundingClientRect()
  //   }
  // }, [])

  // useEffect(() => {
  //     if (workRef.current) {
  //         if (activeWorks) {

  //             const state = Flip.getState(workRef.current);
  //             // Change the layout
  //             workRef.current.style.width = '47%';
  //             workRef.current.style.height = 'unset';
  //             workRef.current.style.aspectRatio = '16 / 9';
  //             // Animate the changes
  //             Flip.from(state, {
  //                 duration: 1,
  //                 delay: 2,
  //                 ease: 'power1.inOut',
  //                 absolute: true,
  //                 scale: true,
  //                 container: workWrapperRef.current
  //             });
  //         }
  //         else if (focusWork !== data.id) {
  //             const state = Flip.getState(workRef.current);
  //             // Change the layout
  //             workRef.current.style.width = initialPositionRef.current.width;
  //             workRef.current.style.height = initialPositionRef.current.height;
  //             // Animate the changes
  //             Flip.from(state, {
  //                 delay: 2,
  //                 duration: 1,
  //                 ease: 'power1.inOut',
  //                 absolute: true,
  //                 scale: true,
  //                 container: workWrapperRef.current
  //             });
  //         }
  //     }
  // }, [activeWorks]);

  // useEffect(() => {
  //     if (workRef.current) {
  //         if (focusWork === data.id) {
  //             focusWorkPositionRef.current = workRef.current.getBoundingClientRect();
  //             gsap.fromTo(workRef.current,
  //                 { x: activeWorksPositionRef.current.left, y: activeWorksPositionRef.current.top, width: activeWorksPositionRef.current.width , height: activeWorksPositionRef.current.height},
  //                 { x: focusWorkPositionRef.current.left, y: focusWorkPositionRef.current.top, width: '47%', height: 'unset', aspectRatio: '16 / 9', duration: 1, delay: 1}
  //             );
  //         } else if (!activeWorks) {
  //             gsap.to(workRef.current, { x: initialPositionRef.current.left, y: initialPositionRef.current.top, width: initialPositionRef.current.width, height: initialPositionRef.current.height, duration: 1, delay: 1 });
  //         }
  //     }
  //     console.log(`focusWork: ${focusWork}`); // Add this line

  // }, [focusWork]);

  const handleWorkClick = (e) => {
    e.preventDefault()
    e.stopPropagation() // Stop event propagation
  }
  const handlePlayerClick = (e) => {
    e.preventDefault()
    e.stopPropagation() // Stop event propagation

    if (focusWork === index) {
      setFocusWork(null) // If focusWork is already index, set it to null
    } else {
      setFocusWork(index)
      setActiveWorks(true) // Otherwise, set focusWork to index
    }
  }

  let workClass, workOrder
  if (focusWork === index) {
    workClass = styles.singleWorkFocus
    workOrder = 0
  } else if (focusWork !== null && focusWork !== index) {
    workClass = styles.singleWorkUnfocused
    workOrder = 2
  } else if (activeWorks) {
    workClass = styles.singleWorkActive
    workOrder = index + 1 // set order based on index
  } else {
    workClass = styles.singleWork
    workOrder = index + 1 // set order based on index
  }

  return (
    <Flipped flipId={`singlework-${index}`} spring={{ stiffness: 500, damping: 100 }} onComplete={() => setIsSingleWorkAnimationDone(true)} onSpringUpdate={() => setIsSingleWorkAnimationDone(false)}
    // onSpringUpdate={(springValue) => {
    //   const start = translateX
    //   const target = 0
    //   // if (workRef.current && activeWorks) {
    //   //   workRef.current.style.transform = `translateX(${start + springValue * (target - start)}%)`
    //   // }
    // }}
    // onStart={({ flippedElement }) => {
    //   // Store the initial translateX value
    //   if (flippedElement) {
    //     flippedElement.dataset.translateX = translateX
    //   }
    // }}
    // onComplete={({ flippedElement }) => {
    //   // Restore the initial translateX value
    //   if (flippedElement && flippedElement.dataset.translateX) {
    //     flippedElement.style.transform = `translateX(${flippedElement.dataset.translateX}%)`
    //   }
    // }}
    >
      <div
      ref={workRef}
      style={{ ...workStyle, order: workOrder }}
      className={workClass}
    >
      <div
      // to={`/single-work/${data.id}`}
      className={styles.workCard}
      // style={{ aspectRatio: `${videoWidth / videoHeight}` }}
      // style={{ paddingTop: `${(videoHeight / videoWidth) * 100}%` }}

        >
       <div className={styles.reactPlayerWrapper}
       style={{ aspectRatio: `${videoWidth} / ${videoHeight}`, width: 'auto' }}
       >
        <ReactPlayer
          url={data.vimeoUrl}
          className={styles.reactPlayer}
          style={{ paddingTop: `${videoHeight / videoWidth}` * 100 + '%' }}
          // style={{ objectFit: 'cover' }}
          // height={activeWorks ? '100%' : ''}
          // width={activeWorks ? '100%' : ''}
          config={{
            vimeo: {
              playerOptions: {
                // maxHeight: '25vh',
                // height: '15vh',
                // width: 'auto'
                // paddingTop: `${videoHeight / videoWidth}` * 100 + '%',
                // byline: false,
                // title: false,
                // portrait: false
              }
            }
          }}
          // className={styles.reactPlayer}
          // style={{ aspectRatio: `${videoWidth / videoHeight}` }}
          height= 'unset'
          width= 'unset'
          controls= {focusWork === index}
          vimeo_logo='false'
          dnt='true'
          play_button_position="center"
          playing={focusWork === index}
          // light={focusWork !== index}
          onReady={player => {
            const vimeoPlayer = player.getInternalPlayer()
            vimeoPlayer.getVideoWidth().then(videoWidth => {
              vimeoPlayer.getVideoHeight().then(videoHeight => {
                setVideoHeight(videoHeight)
                setVideoWidth(videoWidth)
              })
            })
          }}
        />
        </div>

        <Flipped flipKey={`work-${index}`} flipId={`singleWorkWrapper-${index}`}
        // delayUntil={`work-${index}`}
        inverseFlipId={`singlework-${index}`} scale translate
        stagger
        staggerConfig={{
          // the "default" config will apply to staggered elements without explicit keys
          default: {
            // default is .1, 0 < n < 1
            speed: 0.001
          }
        }}
        >
        <div className={styles.metaWrapper} ref={workInfo} onClick = {(event) => event.stopPropagation()} >
            <Flipped flipId={`singleWorkWrapperInner-${index}`} inverseFlipId={`singleWorkWrapper-${index}`} scale translate stagger>
              <div className={styles.meta}>
                  {isSingleWorkAnimationDone && (
                    <>
                <Flipped flipId={ focusWork !== null ? 'singleWorkHeader' : `singleWorkHeader-${index}` } delayUntil={`singleWorkWrapperInner-${index}`} inverseFlipId={`singleWorkWrapperInner-${index}`} scale translate stagger>
                  <div className={styles.workHeader}><h1>{data.artist} – {data.title}</h1></div>
                </Flipped>
                <Flipped flipId={ focusWork !== null ? 'singleWorkInfo' : `singleWorkInfo-${index}`} delayUntil={`singleWorkHeader-${index}`} inverseFlipId={`singleWorkWrapperInner-${index}`} scale translate stagger>
                  <div className={styles.workInfo}><p>{data.role}</p></div>
                </Flipped>
                <Flipped flipId={ focusWork !== null ? 'singleWorkCollaborators' : `singleWorkCollaborators-${index}`} delayUntil={`singleWorkInfo-${index}`} inverseFlipId={`singleWorkWrapperInner-${index}`} scale translate stagger>
                  <div className={styles.workCollaborators}><p>{data.collaborators}</p></div>
                </Flipped>
                  </>
                  )}
            </div>
          </Flipped>
        </div>
        </Flipped>
      </div>
      <div className={styles.playOverlay} onClick={handlePlayerClick}></div>
    </div>
    </Flipped>
  )
}

export default SingleWork

// MouseContextProvider.js
import React, { useState } from 'react'
import MouseXContext from '../contexts/MouseXContext'

const MouseContextProvider = ({ children }) => {
  const [mouseX, setMouseX] = useState(null)
  const [mouseArea, setMouseArea] = useState(null)
  return (
    <MouseXContext.Provider value={{ mouseX, setMouseX, mouseArea, setMouseArea }}>
      {children}
    </MouseXContext.Provider>
  )
}

export default MouseContextProvider

// useMouseX.js
import { useContext } from 'react'
import FocusWorkContext from '../contexts/FocusWorkContext' // Import the new context

export const useFocus = () => {
  const context = useContext(FocusWorkContext) // use the new context

  if (context === undefined) {
    throw new Error('focusWork must be used within a focusWorkContextProvider')
  }

  return context
}

import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useState, useEffect, useRef } from 'react'
import { useQuery, gql } from '@apollo/client'

import { useMouseX } from '../hooks/useMouseX'
import { useWorks } from '../hooks/useWorks'
import { useFocus } from '../hooks/useFocus'
import useIsMobile from '../hooks/useIsMobile'

import styles from './navbar.module.css'
import logoIcon from '../images/wordmark_white.svg'
import { GET_EMAIL } from './queries'

const Navbar = () => {
  const { loading, error, data } = useQuery(GET_EMAIL)
  const [linkStyle, setLinkStyle] = useState({})
  const isMobile = useIsMobile()

  const { setMouseX, mouseX, mouseArea } = useMouseX() // Combine into one line
  const { activeWorks, setActiveWorks, activeAbout, setActiveAbout } = useWorks() // Add this line
  const { focusWork, setFocusWork } = useFocus() // Add this line

  const workLinkRef = useRef(null)
  const contactLinkRef = useRef(null) // Add this line
  const parentRef = useRef(null)

  const handleContactClick = (e) => {
    e.preventDefault()
    if (isMobile === false) {
      window.location.href = `mailto:${data.about.data.attributes.email}`
    } else if (isMobile !== false) {
      setActiveAbout(prevState => !prevState)
    }
    // Initialize state here
  }
  useEffect(() => {
    console.log(activeAbout)
  }, [activeAbout])
  const handleWorkClick = (e) => {
    e.preventDefault()
    // Initialize state here
    // setState('Your state value');
  }

  const handleMouseEnter = (e, path) => {
    setLinkStyle({ color: 'var(--brand-1-hover)' })
  }

  const handleMouseLeave = (e, path) => {
    setLinkStyle({ color: 'var(--brand-1-inactive)' })
  }

  const handleLinkClick = (e, path) => {
    e.preventDefault()
    window.history.pushState('', '', `#${path}`) // Add this line
    if (!activeWorks) {
      const parentWidth = parentRef.current.offsetWidth
      const linkWidth = workLinkRef.current.offsetWidth
      const contemporaryValue = -1 * ((parentWidth / 2) - (linkWidth / 1.4))
      workLinkRef.current.style.transform = `translateX(${contemporaryValue}px)`
      workLinkRef.current.style.color = 'rgba(255, 255, 255, 0.8)' // Add this line
    }
    setTimeout(() => {
      setActiveWorks(prevState => !prevState)
      setFocusWork(null)
      workLinkRef.current.style.transform = 'translateX(0)'
    }, 500)
  }

  const handleMouseMoveCenter = () => {
    setMouseX(null)
  }

  const resetState = () => {
    setLinkStyle({})
    setActiveWorks(false)
    setFocusWork(null)
  }

  useEffect(() => {
    if (activeWorks && parentRef.current) {
      // Move the parentRef div to the top of the screen
      parentRef.current.style.top = 'var(--padding-xl)'
      parentRef.current.style.bottom = 'auto'
      parentRef.current.style.transform = 'translate(-50%,0)'
      parentRef.current.style.transition = 'all 0.5s ease-out'
    } else if (activeAbout && parentRef.current) {
      // Move the parentRef div to the bottom of the screen
      parentRef.current.style.top = 'auto'
      parentRef.current.style.bottom = 'var(--padding-xl)'
      parentRef.current.style.transform = 'translate(-50%, 0)'
      parentRef.current.style.transition = 'all 0.5s ease-out'
    } else if (parentRef.current) {
      // Move the parentRef div back to its original position
      parentRef.current.style.bottom = ''
      parentRef.current.style.top = ''
      parentRef.current.style.transform = ''
      parentRef.current.style.transition = 'all 0.5s ease-out'
    }
  }, [activeWorks, activeAbout]) // Add activeWorks to the dependency array

  useEffect(() => {
    if (mouseArea === 'left' && workLinkRef.current && parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth
      const linkWidth = workLinkRef.current.offsetWidth

      let mappedValue
      let alpha = 0.3 + (mouseX / 50) * 0.5 // Calculate alpha value
      alpha = Math.max(0.3, Math.min(alpha, 0.75)) // Ensure alpha is between 0.5 and 0.75
      workLinkRef.current.style.color = `rgba(255, 255, 255, ${alpha})` // Add this line
      if (mouseX >= 50 && !activeWorks) {
        mappedValue = -1 * ((parentWidth / 2) - (linkWidth / 1.4))
        workLinkRef.current.style.color = 'rgba(255, 255, 255, 0.8)' // Add this line
      } else if (!activeWorks) {
        mappedValue = -1 * (mouseX / 50) * (parentWidth / 2 - linkWidth / 1.4)
      } else if (activeWorks) {
        mappedValue = 0
        // -1 * ((parentWidth / 2) - (linkWidth / 1.4))
        workLinkRef.current.style.color = 'rgba(255, 255, 255, 1 )' // Add this line
      }
      workLinkRef.current.style.transform = `translateX(${mappedValue}px)`
      workLinkRef.current.style.transition = 'transform 0.5s ease'
    } else if (mouseArea === 'right' && contactLinkRef.current && parentRef.current && !activeWorks) {
      const parentWidth = parentRef.current.offsetWidth
      const linkWidth = contactLinkRef.current.offsetWidth

      let alpha = 0.3 + (mouseX / 25) * 0.5 // Calculate alpha value
      alpha = Math.max(0.3, Math.min(alpha, 0.75)) // Ensure alpha is between 0.5 and 0.75
      const alphaShadow = alpha
      contactLinkRef.current.style.color = `rgba(255, 255, 255, ${alpha})` // Add this line
      let mappedValue
      if (mouseX >= 25) {
        mappedValue = (parentWidth / 2) - (linkWidth / 1.5)
        contactLinkRef.current.style.color = 'rgba(255, 255, 255, 1)' // Add this line
      } else {
        mappedValue = (mouseX / 25) * ((parentWidth / 2) - (linkWidth / 1.75))
      }
      contactLinkRef.current.style.transform = `translateX(${mappedValue}px)`
      contactLinkRef.current.style.transition = 'transform 0.5s ease'
      // contactLinkRef.current.style.textShadow = `0px 0px 3px rgba(255, 255, 255, 0)`; // Add this line
    }
  }, [mouseX, mouseArea, activeWorks]) // Add mouseArea to the dependency array

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return (
        <nav className={styles.navbar} ref={parentRef} onMouseMove={handleMouseMoveCenter}>
          <div className={styles.links}>
                <Link to="/contact"
                onClick={handleContactClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={styles.contactLink} ref={contactLinkRef}>contact</Link>
            </div>

            <div className={styles.brand} onClick={resetState} >
            <Link to="/" >
                <img
                    className={styles.logoIcon}
                    alt=""
                    src={logoIcon}
                />
            </Link>
            </div>

            <div className={styles.links}>
                <Link to="/works" onClick={(e) => handleLinkClick(e, 'works')}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={styles.workLink} ref={workLinkRef}>works</Link>
            </div>
        </nav>
  )
}

export default Navbar

// queries.js
import { gql } from '@apollo/client'

export const GET_EMAIL = gql`
query getEmail {
  about {
    data {
      attributes {
        email
      }
    }
  }
}
`

export const WORKS = gql`
query getWorks{
  work {
    data {
      attributes {
        works{
          __typename
          ...on ComponentContentWork {
            title
            artist
            role
            collaborators
            vimeoUrl
          }
        }
      }
    }
  }
}
  `

export const ABOUT = gql`
query getAbout{
  about{
    data{
      attributes{
        content
        profileImage{data{attributes{url}}}
        phone
        email
        impressum
  }}}
}
`

// Add other queries in a similar fashion

import React, { useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './ApolloSetup'

import { Routes, Route, Navigate } from 'react-router-dom'
import MouseContextProvider from './contexts/MouseContextProvider'
import ActiveWorksContextProvider from './contexts/ActiveWorksContextProvider'
import FocusWorkContextProvider from './contexts/FocusWorkContextProvider'
import Main from './components/main'
import Contact from './components/contact'
import Works from './components/works'
import SingleWork from './components/single-work' // Import the missing component
import Navbar from './components/navbar'

function App () {
  return (
      <ApolloProvider client={client}>
          <FocusWorkContextProvider>
          <ActiveWorksContextProvider>
          <MouseContextProvider>
            <Routes>
              <Route exact path="/" element={<Main />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/works">
                <Route index element={<Works />} />
                  <Route path=":id" element={<SingleWork />} />
                </Route>
              <Route path="*" element={ <Navigate to="/" />} />
            </Routes>
          <Navbar />
          </MouseContextProvider>
          </ActiveWorksContextProvider>
          </FocusWorkContextProvider>
      </ApolloProvider>
  )
}

export default App

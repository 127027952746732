import React, { useContext, useEffect, useState, useRef } from 'react'
import { useMouseX } from '../hooks/useMouseX'
import { useWorks } from '../hooks/useWorks'
import { useFocus } from '../hooks/useFocus'
import useIsMobile from '../hooks/useIsMobile'

import SingleWork from './single-work'
import styles from './works.module.css'
import gsap from 'gsap' // Make sure to install gsap with npm install gsap
import { Flipper, Flipped } from 'react-flip-toolkit'

const Works = ({ data, flippedProps }) => {
  const { mouseArea } = useMouseX()
  const { activeWorks, setActiveWorks } = useWorks()
  const { focusWork } = useFocus()
  const isMobile = useIsMobile()

  const workWrapperRef = useRef(null)
  const [transformValues, setTransformValues] = useState(
    data.map(() => -100 - Math.round(Math.random() * 40))
  )
  const [randomXYValues, setRandomXYValues] = useState([])

  useEffect(() => {
    if (mouseArea === 'center' || activeWorks || !activeWorks) {
      const randomValues = data.map(() => -100 - Math.round(Math.random() * 40))
      setTransformValues(randomValues)

      const randomXY = data.map(() => ({
        x: Math.round(Math.random() * isMobile === false ? 12 : 1),
        y: Math.round(Math.random() * isMobile === false ? 6 : 0.5)
      }))
      setRandomXYValues(randomXY)
    }
  }, [activeWorks, data, isMobile, mouseArea])

  return (
    <>
      {data.map((item, index) => {
        return (
        // <Flipped key={index} flipId={`work-${index}`} inverseFlipId='works' scale>
            <SingleWork key={index} flipId={`work-${index}`} data={item} index={index} transformValue={transformValues[index]} randomXY={randomXYValues[index]}/>
        // </Flipped>
        )
      })}
    </>
  )
}

export default Works

// useMouseX.js
import { useContext } from 'react'
import MouseXContext from '../contexts/MouseXContext'

export const useMouseX = () => {
  const context = useContext(MouseXContext)

  if (context === undefined) {
    throw new Error('useMouseX must be used within a MouseXContextProvider')
  }

  return context
}

import React, { useRef, useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useMouseX } from '../hooks/useMouseX'
import { useWorks } from '../hooks/useWorks'
import { useFocus } from '../hooks/useFocus'
import useIsMobile from '../hooks/useIsMobile'
import Works from './works'
import Contact from './contact'
import styles from './main.module.css'
import { WORKS, ABOUT } from './queries'
import { Flipper, Flipped } from 'react-flip-toolkit'
import getToken from '../helpers/getToken'

const Main = () => {
  const { mouseX, setMouseX } = useMouseX()
  const { mouseArea, setMouseArea } = useMouseX()
  const { activeWorks, setActiveWorks, activeAbout, setActiveAbout } = useWorks() // Add this line
  const { focusWork, setFocusWork } = useFocus() // Add this line
  const isMobile = useIsMobile()

  const leftAreaRef = useRef(null)
  const rightAreaRef = useRef(null)

  const [lastMouseXPercentage, setLastMouseXPercentage] = useState(null)

  const { loading: worksLoading, error: worksError, data: worksData } = useQuery(WORKS)
  const { loading: aboutLoading, error: aboutError, data: aboutData } = useQuery(ABOUT)

  // useEffect(() => {
  //   async function fetchData () {
  //     const token = await getToken()
  //     localStorage.setItem('token', token)
  //   }
  //   fetchData()
  // }, [])

  useEffect(() => {
    const handleMouseLeaveWindow = (event) => {
    }
    // Add the event listener when the component mounts
    window.addEventListener('mouseout', handleMouseLeaveWindow)
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('mouseout', handleMouseLeaveWindow)
    }
  }, [])

  if (worksLoading || aboutLoading) return <p>Loading...</p>
  if (worksError || aboutError) return <p>Error :(</p>
  const works = worksData.work.data.attributes.works
  const about = aboutData.about.data.attributes

  const handleLeftAreaClick = () => {
    setActiveWorks(prevState => !prevState)
    setFocusWork(null)
  }

  const handleMouseMove = (event, areaRef) => {
    if (areaRef.current && areaRef.current.contains(event.target)) {
      const { left, width } = areaRef.current.getBoundingClientRect()
      const x = event.clientX - left
      const mouseXPercentage = Math.round(areaRef === leftAreaRef ? 100 - (x / width * 100) : x / width * 100)
      // areaRef === leftAreaRef ?
      // window.history.pushState("", "", "/#works") :
      // window.history.pushState("", "", "/#about"); // Add this line
      setMouseX(mouseXPercentage)
      setMouseArea(areaRef === leftAreaRef ? 'left' : 'right')
      window.history.pushState('', '', '/')
    }
  }

  const handleMouseLeave = (event, areaRef) => {
  //   // setTimeout(() => {
  //   //   // window.history.pushState("", "", "/");
  //   // }, 500)

    if (event.relatedTarget === null) {
      setMouseX(null)
    }
  }

  const handleMouseMoveCenter = () => {
    setMouseX(null)
    // setMouseArea('center')
  }

  let leftAreaClass
  if (focusWork !== null) {
    leftAreaClass = styles.leftAreaFocus
  } else
    if (activeWorks) {
      leftAreaClass = styles.leftAreaActive
    } else {
      leftAreaClass = styles.leftArea
    }

  return (
    <Flipper flipKey={`${activeWorks}-${focusWork}`} >
      <main className={styles.main}>
        <Flipped flipId='leftArea'>
          <div ref={leftAreaRef} className={activeWorks ? styles.leftAreaActive : styles.leftArea}
          onMouseMove={isMobile === true ? null : (e) => handleMouseMove(e, leftAreaRef)}
          onMouseLeave={isMobile === true ? null : handleMouseLeave}
          onClick={handleLeftAreaClick}
          >
            <Flipped flipId='works' inverseFlipId='leftArea'>
            <div className={activeWorks ? styles.worksActive : styles.works}>
            <Works data={works} />
            </div>
            </Flipped>
          </div>
        </Flipped>
          <div className={styles.centerArea} onMouseMove={handleMouseMoveCenter} style={activeWorks || focusWork ? { display: 'none' } : {}}></div>
        <div ref={rightAreaRef} className={ activeAbout && isMobile !== false ? styles.rightAreaActive : styles.rightArea }
        onMouseMove={isMobile === true ? null : (e) => handleMouseMove(e, rightAreaRef)}
        onMouseLeave={isMobile === true ? null : handleMouseLeave}
        onClick={isMobile === true ? () => setActiveAbout(prevState => !prevState) : null}
        style={ activeWorks || focusWork ? { display: 'none' } : {}}
        >
          <Contact data={about}/>
        </div>
      </main>
    </Flipper>
  )
}

export default Main

import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMouseX } from '../hooks/useMouseX'
import { useWorks } from '../hooks/useWorks'
import useIsMobile from '../hooks/useIsMobile'
import styles from './contact.module.css'

const Contact = ({ data, active }) => {
  const { mouseX, mouseArea } = useMouseX()
  const { activeWorks, activeAbout } = useWorks() // Add this line
  const isMobile = useIsMobile()

  const formattedImpressum = data.impressum.replace(/\n/g, '<br />')
  // store the slug of the url in a variable
  const location = useLocation()

  const baseUrl = process.env.REACT_APP_API_URL
  const imageUrl = data.profileImage && data.profileImage.data
    ? `${baseUrl}${data.profileImage.data.attributes.url}`
    : null

  // take photo url from strapi and add it to the image tag
  // if there is no photo, don't show the image tag

  const calculateTranslateX = (mouseX) => {
    if (mouseArea === 'right' && !activeWorks) {
      if (mouseX === null) {
        return 100 // Default state, off-screen
      } else if (mouseX >= 25) {
        // Map mouseX to range between 100% and 0
        const translateX = 0
        return Math.max(translateX, 0) // Ensure it doesn't exceed 0
      } else {
        // Map mouseX to range between 100% and 0
        const translateX = 100 - (mouseX / 95 * 4) * 100
        return Math.max(translateX, 0) // Ensure it doesn't exceed 0
      }
    }
    return 100 // If not in the right area, keep it off-screen
  }

  // if location === /contact, then translateX = 0, but before the calculateTranslateX function

  const translateX = calculateTranslateX(mouseX)

  const contactStyle = {
    transform: `translateX(${translateX}%)`,
    transition: 'transform 0.5s ease'
  }

  return (
        <div style={ isMobile === false ? contactStyle : null } className={ isMobile !== false && activeAbout ? styles.contactActive : styles.contact}>
            { imageUrl && <img className={styles.profileImage} alt="" src={imageUrl} /> }
       <div className={styles.aboutText}>
           <p className={styles.loremIpsumDolor}>
             {data.content}
           </p>
           <p className={styles.loremIpsumDolor}>&nbsp;</p>
           <a className={styles.a} href={`tel:${data.phone}`}>{data.phone}</a>
           <a className={styles.a} href={`mailto:${data.email}`}>{data.email}</a>
           <p className={styles.p}>&nbsp;</p>
           <p className={styles.loremIpsumDolor}><div dangerouslySetInnerHTML={{ __html: formattedImpressum }} /></p>
       </div>
    </div>
  )
}

export default Contact

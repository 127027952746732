import React, { useState } from 'react'
import FocusWorkContext from '../contexts/FocusWorkContext'

const FocusWorkContextProvider = ({ children }) => {
  const [focusWork, setFocusWork] = useState(null)

  return (
    <FocusWorkContext.Provider value={{ focusWork, setFocusWork }}>
      {children}
    </FocusWorkContext.Provider>
  )
}

export default FocusWorkContextProvider
